export const netPrices = {
    vat: 1.2,
    weekdays: {
        dayTime: 93,
        evening: 137.5,
        night: 215,
    },
    weekends: {
        dayTime: 137.5,
        night: 215,
    },
}